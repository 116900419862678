import { useAccount, useMsal } from "@azure/msal-react";
import { useState } from "react";
import ReactQuill from "react-quill"
import { GridLoader } from "react-spinners";
import { sendCustomerSupportEmail } from "../managers/CustomerSupportFunctions";

export default function ContactUs()
{
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const [reportContent, setReportContent] = useState('');
    const [subject, setSubject] = useState('');
    const [category, setCategory] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);

    return (
        <>
        {
            isLoading
            &&
            <div className="h-full w-full flex flex-col items-center justify-center pr-[20%]">
                <GridLoader size='15px' color='rgb(24 24 27)' />
                <span className="text-xl font-normal">Powered by <b>Jensen Labs</b></span>
            </div>
        }
        {
            !isLoading 
            &&
            <div className="h-full w-5/6 pl-4 bg-zinc-100 py-4 pr-4 rounded-r-xl flex flex-row">
                <div className="w-4/5 pr-8">
                    <p className="text-2xl font-semibold border-b-2 border-b-zinc-900 pb-2">Waves Customer Support</p>
                    <p className="text-sm">
                        We're sorry to hear you're having trouble! Would you mind providing us with some details to help better 
                        your experience?
                    </p>
                    <select className="bg-zinc-900 text-zinc-100 font-semibold p-2 mb-4" onChange={(e) => {setCategory(e.currentTarget.value)}}>
                        <option selected disabled>
                            Select Category
                        </option>
                        {
                            ['Technical issue', 'Problem with making a flag', 'Problem viewing a flag', 'General issue', 'Feedback (we really appreciate this!)']
                            .map(issue => {
                                return (
                                    <option key={issue} className="font-semibold">
                                        {issue}
                                    </option>
                                )
                            })
                        }
                    </select>
                    <p className="text-xl font-semibold">Subject</p>
                    <input type='text' value={subject} onChange={(e) => {setSubject(e.target.value)}} className="w-full h-[50px] rounded-lg bg-zinc-300 text-zinc-900 font-semibold px-8 placeholder:text-white text-xl mb-9" />
                    <p>If you'd like to call us, please contact us at <b>0423 912 225</b> or contact the Webmaster at <b>sales@jensenlabs.dev</b></p>
                    <button className="w-[250px] py-3 bg-gradient-to-r from-orange-400 to-purple-500 text-white font-semibold rounded-lg transition-all duration-150 hover:scale-105"
                    onClick={async () => {
                        setIsLoading(true);
                        let result = await sendCustomerSupportEmail(account?.name, subject, category, reportContent);
                        if (result)
                        {
                            setShowThankYouMessage(true);
                        }
                        else
                        {
                            alert("An error occurred. Please email the webmaster or call the number on screen for more help.");
                        }
                        setIsLoading(false);
                    }}>
                        Submit Request
                    </button>
                    {
                        showThankYouMessage 
                        &&
                        <p className="text-green-500 pt-2 font-semibold text-sm">Thank you! We received your message and will be in touch soon.</p>
                    }
                </div>
                <div className="h-[90%]">
                    <p className="text-lg">Would you be able to provide us with some details?</p>
                    <ReactQuill modules={{
                        toolbar: [
                            [{ font: [] }],
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            ["bold", "italic", "underline", "strike"],
                            [{ color: [] }, { background: [] }],
                            [{ script:  "sub" }, { script:  "super" }],
                            ["blockquote", "code-block"],
                            [{ list:  "ordered" }, { list:  "bullet" }],
                            [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
                            ["link"],
                            ["clean"],
                        ],
                    }} theme="snow" onKeyDown={(e) => {
                        if (reportContent.length > 20000)
                            setReportContent(reportContent);
                    }} value={reportContent} onChange={setReportContent} className=' sm:h-[75%] md:h-[85%] max-w-[750px] pb-16' />
                    <small>Characters remaining: {20000 - reportContent.length}</small>
                </div>
            </div>
        }
        </>
        
        
    )
}

