import { useMsal } from "@azure/msal-react";
import { isBrowser, isMobile } from 'react-device-detect';


export default function SignInPage()
{
    const { instance } = useMsal();
    return (
        <>
            {
                isBrowser &&
                <div className="w-screen h-screen flex flex-col items-center justify-center">
                    <p className="font-bold uppercase text-8xl text-zinc-700">Assurance</p>
                    <p className="text-5xl font-bold bg-clip-text bg-gradient-to-r from-orange-400 to-purple-500 text-transparent mb-6">by WAVES</p>
                    <button className="w-[250px] py-3 bg-gradient-to-r from-orange-400 to-purple-500 text-white font-semibold rounded-lg transition-all duration-150 hover:scale-105"
                    onClick={() => {handleLogin(instance)}}>
                        Sign In
                    </button>
                </div>
            }
            {
                isMobile &&
                <div className="w-screen h-screen flex flex-col items-center justify-center">
                    <p className="font-bold uppercase text-5xl text-zinc-700">Assurance</p>
                    <p className="text-3xl font-bold bg-clip-text bg-gradient-to-r from-orange-400 to-purple-500 text-transparent mb-6">by WAVES</p>
                    <p className="text-2xl py-3 bg-clip-text text-transparent bg-gradient-to-r from-orange-400 to-purple-500 drop-shadow-2xl font-semibold rounded-lg transition-all duration-150 hover:scale-105">
                        Mobile Coming Soon
                    </p>
                </div>
            }
        </>
    )
}



function handleLogin(instance: any) {
    instance.loginRedirect().catch((e: any) => {
        console.log(e);
    });
}
