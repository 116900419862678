import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import GridLoader from "react-spinners/GridLoader";
import { ApiServiceWorker } from "../managers/ApiServiceWorker";
import CreateModalProfile from "../modals/CreateProfileModal";
import { Profile } from "../models/Profile";
import ProfileResultsComponent from "./ProfileResultsComponent";



export default function SearchUpProfile(props: any)
{
    // Utility
    const [isSearching, setIsSearching] = useState(false);
    const [showNewProfileModal, setShowNewProfileModal] = useState(false);
    
    // Parameters
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState<any>();
    const [address, setAddress] = useState('');
    const [profilesFound, setProfilesFound] = useState(false);
    const [profileList, setProfileList] = useState<Profile[]>([]);

    const api = props.api as ApiServiceWorker;

    return (
        <>
        {
            isSearching &&
            <div className="h-full w-full flex flex-col items-center justify-center pr-[15%]">
                <GridLoader size='15px' color='rgb(24 24 27)' />
                <span className="text-xl font-normal">Powered by <b>Jensen Labs</b></span>
            </div>
        }
        {
            !isSearching &&
            <div className="w-full h-full flex flex-row gap-4">
                <CreateModalProfile show={showNewProfileModal} onHide={() => {setShowNewProfileModal(false)}} 
                name={name} email={email} phoneNumber={phoneNumber} address={address} api={api} />
                <section className="h-full w-full max-w-[1000px] flex flex-col pl-4 pt-6 gap-[22px]">
                    <input type='text' value={name} onChange={(e) => {setName(e.target.value)}} className="w-full h-[70px] rounded-lg bg-zinc-300 text-zinc-900 font-semibold pl-8 placeholder:text-white text-xl" placeholder="name" />
                    <input type='email' value={email} onChange={(e) => {setEmail(e.target.value)}} className="w-full h-[70px] rounded-lg bg-zinc-300 text-zinc-900 font-semibold  pl-8 placeholder:text-white text-xl" placeholder="email" />
                    <PhoneInput 
                        defaultCountry="AU"
                        placeholder="phone number"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        limitMaxLength={true}
                        className='w-full h-[70px] bg-zinc-300 text-zinc-900 font-semibold rounded-lg pl-8'
                    />
                    <input type='text' value={address} onChange={(e) => setAddress(e.target.value)} className="w-full h-[70px] rounded-lg bg-zinc-300 text-zinc-900 font-semibold pl-8 placeholder:text-white text-xl" placeholder="address" />
                    <button className="w-64 bg-gradient-to-r from-orange-400 to-purple-500 py-3 text-white font-bold rounded-lg transition-all duration-150 hover:scale-105"
                    onClick={() => {
                        setIsSearching(true);
                        
                        api.makeSearch(name, email, phoneNumber, address)
                        .then(profileList => {
                            if (profileList === null)
                            {
                                // Error!
                                alert("There was an error with the fields! Please make sure you have at least a name provided!");
                            }
                            else
                            {
                                if (profileList.length > 0)
                                {
                                    setProfileList(profileList);
                                    setProfilesFound(true);
                                }
                                else if (profileList.length === 0) // Not null, just 0!
                                {
                                    // No profile! Trigger the modal to ask if they would like to put it there!
                                    setShowNewProfileModal(true);
                                    setProfileList([]);
                                }
                            }
                            setIsSearching(false);
                        })
                    }}>Search</button>
                </section>
                {
                    profilesFound &&
                    <>
                    <section className="w-full h-full max-w-[500px] flex flex-col">
                        <ProfileResultsComponent profileList={profileList} />
                        <button className="w-[250px] bg-red-600 mx-auto mt-2 rounded-xl text-white transition-all duration-150 p-2 hover:bg-red-500"
                        onClick={() => {setShowNewProfileModal(true)}}>
                            Can't find the profile you're looking for?
                        </button>
                    </section>
                    </>
                }
            </div>
        }
        </>
    )
}
