import { Modal } from "react-bootstrap";
import { ApiServiceWorker } from "../managers/ApiServiceWorker";

export default function CreateModalProfile(props: any)
{
    const api = props.api as ApiServiceWorker;
    return (
        <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header className="bg-gradient-to-r from-orange-500 to-purple-600 text-white">
                <Modal.Title>
                    <p className="text-3xl">No Profile Found</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="text-xl ml-16">Sorry, we couldn't find a profile with those details? Would you like to create a new one?</p>
                <div className="w-full flex flex-col gap-2 mx-16">
                    <p className="text-xl font-semibold border-b-2 border-b-zinc-900 mr-32 pb-2">Profile Details</p>
                    <p>NAME: <b>{props.name}</b></p>
                    <p>EMAIL: <b>{props.email === '' ? 'No email provided' : props.email}</b></p>
                    <p>PHONE NUMBER: <b>{props.phoneNumber ?? 'No phone number provided'}</b></p>
                    <p>ADDRESS: <b>{props.address === '' ? 'No address provided' : props.address}</b></p>
                </div>
                <button className="ml-16 w-64 bg-gradient-to-r from-orange-400 to-purple-500 py-3 text-white font-bold rounded-lg transition-all duration-150 hover:scale-105"
                onClick={() => {
                    api.createNewProfile(props.name, props.email, props.phoneNumber, props.address)
                    .then(res => {
                        if (res)
                        {
                            api.updateRecents(res);
                            props.onHide();
                        }
                        else
                        {
                            alert('There was an error creating this profile. Please contact support if this issue persists.');
                        }
                    })
                    .catch(e => console.error(e));
                }}>
                    Create Profile
                </button>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    )
}