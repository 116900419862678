/**
 * Dispatches a customer support email and then return true upon success.
 * @param {string | undefined} from the currently signed in user
 * @param {string} subject the subject of the support request
 * @param {string} category the category of the request
 * @param {string} htmlContents the html contents of the support request
 */
export async function sendCustomerSupportEmail(from, subject, category, htmlContents)
{
    const emailjs = require('@emailjs/browser');
    var templateParams = {
        from: from,
        category: category,
        subject: subject,
        reportContents: htmlContents
    };

    // We're in a world of hurt if we run out of customer support emails.
    let response = emailjs.send('service_avan6af', 'template_tj9avsh', templateParams, 'user_FMWgXWN9RkR61NHYdXhXA')
    .then(response => true)
    .catch(err => false);
    return response;
}