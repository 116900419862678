import { useEffect, useState } from "react";
import { GridLoader } from "react-spinners";
import { ApiServiceWorker } from "../managers/ApiServiceWorker";
import ViewReportModal from "../modals/ViewReportModal";
import { Profile } from "../models/Profile";

export default function RecentProfiles(props: any)
{
    const api = props.api as ApiServiceWorker;
    
    const [recentProfiles, setRecentProfiles] = useState<Profile[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);

    const [profile, setProfile] = useState<Profile | null>(null);


    useEffect(() => {
        setIsSearching(true);
        // For some reason the constructor can't initialise the container for personalProfile, so we have to re-init it here...
        // UNACCEPTABLE.
        api.getRecents()
        .then(profiles => {
            setRecentProfiles(profiles);
            setIsSearching(false);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
        {
            isSearching &&
            <div className="h-full w-full flex flex-col items-center justify-center pr-[15%]">
                <GridLoader size='15px' color='rgb(24 24 27)' />
                <span className="text-xl font-normal">Powered by <b>Jensen Labs</b></span>
            </div>
        }
        {
            !isSearching &&
            <div className="h-full w-full pl-4 text-zinc-700 overflow-auto flex flex-col gap-2">
                <ViewReportModal show={showReportModal} onHide={() => {setShowReportModal(false);}} profile={profile} api={api} />
                <div className="w-full h-[80%] overflow-auto flex flex-col gap-2 scrollbar">
                    {
                        recentProfiles.map((profile: Profile) => {
                            return (
                                <div className="w-full min-h-20 rounded-xl text-md bg-zinc-300 transition-all duration-150 hover:text-white hover:bg-zinc-900 flex flex-col px-4 p-2 hover:cursor-pointer"
                                onClick={() => {
                                    setProfile(profile);
                                    setShowReportModal(true);
                                }}>
                                    <div className="w-full text-xl font-semibold">
                                        <span>{profile.name}</span>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <span>{profile.email}</span>
                                        <span>{profile.phoneNumber ?? 'no phone number'}</span>
                                        <span>{profile.address}</span>
                                    </div>
                                </div>
                            )
                        }).reverse()
                    }
                    {
                        recentProfiles &&
                        <div className="w-full h-full flex items-center justify-center pr-[23%]">
                            <p>Make a search to populate this list!</p>
                        </div>
                    }
                </div>
            </div>
        }
        </>
        
    )
}