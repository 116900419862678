import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { GridLoader } from "react-spinners";
import { ApiServiceWorker } from "../managers/ApiServiceWorker";
import { Report } from "../models/Report";
import { useAccount, useMsal } from "@azure/msal-react";
import { Profile } from "../models/Profile";



export default function MakeAFlag(props: any)
{
    
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const api = props.api as ApiServiceWorker;
    
    const [reportContent, setReportContent] = useState('');
    const [recentProfiles, setRecentProfiles] = useState<Profile[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [showThankyouText, setShowThankyouText] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);


    useEffect(() => {
        setIsSearching(true);
        // For some reason the constructor can't initialise the container for personalProfile, so we have to re-init it here...
        // UNACCEPTABLE.
        api.getRecents()
        .then(profiles => {
            setRecentProfiles(profiles);
            setIsSearching(false);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
        {
            isSearching &&
            <div className="h-full w-full flex flex-col items-center justify-center pr-[15%]">
                <GridLoader size='15px' color='rgb(24 24 27)' />
                <span className="text-xl font-normal">Powered by <b>Jensen Labs</b></span>
            </div>
        }
        {
            !isSearching 
            &&
            <div className="h-full w-full flex flex-row pl-4 items-end">
                <div className="w-full h-full flex flex-col justify-start pr-4">
                    <div className="w-full h-[80%] overflow-auto flex flex-col gap-2 mb-4 scrollbar">
                        {
                            recentProfiles.map((profile: Profile) => {
                                return (
                                    <div key={profile.id} className="w-full min-h-20 rounded-xl text-md bg-zinc-300 transition-all duration-150 hover:text-white hover:bg-zinc-900 flex flex-col px-4 p-2 hover:cursor-pointer
                                    aria-selected:bg-gradient-to-r aria-selected:from-orange-500 aria-selected:to-purple-600 aria-selected:text-white aria-selected:cursor-default" aria-selected={profile.id === selectedProfile?.id}
                                    onClick={() => {
                                        setSelectedProfile(profile);
                                    }}>
                                        <div className="w-full text-xl font-semibold">
                                            <span>{profile.name}</span>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <span>{profile.email}</span>
                                            <span>{profile.phoneNumber ?? 'no phone number'}</span>
                                            <span>{profile.address}</span>
                                        </div>
                                    </div>
                                )
                            }).reverse()
                        }
                        {
                            recentProfiles.length === 0 &&
                            <div className="w-full h-full flex items-center justify-center">
                                <p>Make a search to populate this list!</p>
                            </div>
                        }
                    </div>
                    <button className="w-64 bg-gradient-to-r from-orange-400 to-purple-500 py-3 text-white font-bold rounded-lg transition-all duration-150 hover:scale-105"
                    onClick={() => 
                        {
                            if (reportContent.replace(/<(.|\n)*?>/g, '').trim().length === 0)
                            {
                                alert('Please provide us with some details if you can!');
                                return;
                            }
                            setIsSearching(true);
                            // TODO: trigger the report submission and then notify the user that we're grateful for them doing this!
                            api.addFlagToProfile(selectedProfile!, new Report(selectedProfile!, `${account?.name}`, reportContent))
                            .then(res => {
                                if (res)
                                {
                                    // Future feature: send an email so that Assurance can follow up with the customer and ask them about how they felt submitting a report.
                                    // TODO: Set some sort of thank you text.
                                    setShowThankyouText(true);
                                    window.location.reload();
                                }
                                else
                                {
                                    // TODO: alert that there was an error uploading their report.
                                }
                            })
                            setIsSearching(false);
                        }
                    }>Submit Report</button>
                    {
                        showThankyouText &&
                        <p className="text-xl font-semibold">Thank you for your submission! We greatly appreciate you helping out the boating community!</p>
                    }
                </div>
                <div className="w-3/4 h-full flex flex-col justify-start items-center">
                    <p className="text-center -mt-8 pb-12 text-zinc-700 font-semibold text-lg">Can you provide some information?</p>
                    <ReactQuill modules={{
                        toolbar: [
                            [{ font: [] }],
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            ["bold", "italic", "underline", "strike"],
                            [{ color: [] }, { background: [] }],
                            [{ script:  "sub" }, { script:  "super" }],
                            ["blockquote", "code-block"],
                            [{ list:  "ordered" }, { list:  "bullet" }],
                            [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
                            ["link", "image", "video"],
                            ["clean"],
                        ],
                    }} theme="snow" value={reportContent} onChange={setReportContent} className='w-full h-full sm:h-[75%] md:h-[85%] -mt-14' />
                </div>
            </div>
        }
        </>
    )
}