import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { BrowserRouter } from 'react-router-dom';
import HomePage from './pages/Home';
import SignInPage from './pages/SignInPage';


const b2cPolicies = {
  names: {
      signIn: "B2C_1_Waves_Assurance_Sign_In_Policy"
  },
  authorities: {
      signIn: {
          authority: "https://wavesassurancecustomer.b2clogin.com/wavesassurancecustomer.onmicrosoft.com/B2C_1_Waves_Assurance_Sign_In_Policy",
      }
  },
  authorityDomain: "wavesassurancecustomer.b2clogin.com"
}

const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_APP_CLIENT_ID}`, // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.signIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: window.location.href, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["Mail.Send"] // This needs to be sorted out for customer support email
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

// create PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(msalConfig);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <MsalProvider instance={publicClientApplication}>
      <AuthenticatedTemplate>
        <HomePage />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignInPage />
      </UnauthenticatedTemplate>
    </MsalProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
