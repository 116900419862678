import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import 'react-phone-number-input/style.css'
import { ProfilePicture } from "../components/ProfileDropdownComponent";
import SearchUpProfile from "../components/SearchUpProfile";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAccount, useMsal } from "@azure/msal-react";
import Avatar from "react-avatar";
import { IPublicClientApplication } from "@azure/msal-browser";
import RecentProfiles from "../components/RecentProfiles";
import MakeAFlag from "../components/MakeAFlag";
import ContactUs from "../components/ContactUs";
import { ApiServiceWorker } from "../managers/ApiServiceWorker";


export default function HomePage()
{
    const {instance, accounts} = useMsal();
    const account = useAccount(accounts[0]);
    
    const [pageType, setPageType] = useState('search');

    const [api] = useState(new ApiServiceWorker(`${account?.idTokenClaims?.emails![0]}`));

    return (
        <main className="w-screen h-screen">
            <div className='w-full h-1/6 flex flex-row justify-between text-zinc-700 px-32 items-center'>
                <div className="font-bold">
                    <span className="text-6xl capitalize">ASSURANCE</span>
                    <p className="text-3xl">by <span className="text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-purple-500">WAVES</span></p>
                </div>
                <section className="flex flex-row">
                    <div className="pr-6 flex flex-col">
                        <span className="font-bold text-zinc-700 text-lg text-end">Logged in as</span>
                        <span className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-purple-500 text-2xl">{account?.name}</span>
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle as={ProfilePicture} id='dropdown-basic'>
                            <div className="w-16 h-16 flex items-center justify-center font-bold uppercase hover:cursor-pointer">
                                <Avatar name={account?.name} color='rgb(24 24 27)' className="rounded-xl" size="72px"/>
                            </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="flex flex-col">
                            <Dropdown.Item className="font-bold text-zinc-700"
                            onClick={() => {setPageType('contact')}}>
                                Contact Us
                            </Dropdown.Item>
                            <Dropdown.Item className="font-bold text-zinc-700"
                            onClick={() => {signOut(instance)}}>
                                Sign Out
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </section>
            </div>
            <main className='w-full h-[81%] text-zinc-800 flex flex-col items-start justify-center'>
                {
                    pageType === 'search'
                    &&
                    <div className="mx-auto mb-8 text-center">
                        <p className="font-bold text-2xl pb-2 mx-auto">Use Any Datapoint to Make a Search</p>
                        <small className="text-lg text-zinc-400 mx-auto">(more datapoints return more results. Please make sure you have filled out the fields correctly.)</small>
                    </div>
                }
                {
                    pageType === 'recents'
                    &&
                    <div className="mx-auto mb-8  text-center">
                        <p className="font-bold text-2xl pb-2 mx-auto">View Recent Profiles</p>
                        <small className="text-lg text-zinc-400 mx-auto">(click to open profile)</small>
                    </div>
                }
                {
                    pageType === 'flag'
                    &&
                    <div className="mx-auto mb-8  text-center">
                        <p className="font-bold text-2xl pb-2 mx-auto">Make a New Flag</p>
                        <small className="text-lg text-zinc-400 mx-auto">(Please select a searched boater and provide us with some details)</small>
                    </div>
                }
                {
                    pageType === 'contact'
                    &&
                    <div className="mx-auto mb-8  text-center">
                        <p className="font-bold text-2xl pb-2 mx-auto">Contact Us</p>
                        <small className="text-lg text-zinc-400 mx-auto">(fill out the form and we'll be in touch)</small>
                    </div>
                }
                <div className="w-full lg:w-[75%] max-w-[1500px] h-[500px]  flex flex-row mx-auto">
                    <section className="h-full w-1/5 min-w-[200px] border-r-2 border-r-zinc-800 pr-2 flex flex-col justify-around">
                        <button className="w-full mb-4 disabled:bg-zinc-800 disabled:text-white font-bold h-[60px] rounded-xl text-end pr-8 text-xl transition-all duration-150 flex items-center justify-end hover:cursor-pointer text-zinc-700"
                        disabled={pageType === 'search'}
                        onClick={() => {setPageType('search')}}>
                            Search
                        </button>
                        <button className="w-full mb-4 disabled:bg-zinc-800 disabled:text-white font-bold h-[60px] rounded-xl text-end pr-8 text-xl transition-all duration-150 flex items-center justify-end hover:cursor-pointer text-zinc-700"
                        disabled={pageType === 'recents'}
                        onClick={() => {setPageType('recents')}}>
                            Recents
                        </button>
                        <button className="w-full mb-4 disabled:bg-zinc-800 disabled:text-white font-bold h-[60px] rounded-xl text-end pr-8 text-xl transition-all duration-150 flex items-center justify-end hover:cursor-pointer text-zinc-700"
                        disabled={pageType === 'flag'}
                        onClick={() => {setPageType('flag')}}>
                            Make a Flag
                        </button>
                        <button className="w-full mb-4 disabled:bg-zinc-800 disabled:text-white font-bold h-[60px] rounded-xl text-end pr-8 text-xl transition-all duration-150 flex items-center justify-end hover:cursor-pointer text-zinc-700"
                        disabled={pageType === 'contact'}
                        onClick={() => {setPageType('contact')}}>
                            Contact Us
                        </button>
                    </section>
                    <section className="w-full h-full">
                    {
                        pageType === 'search'
                        &&
                        <SearchUpProfile api={api} />
                    }
                    {
                        pageType === 'recents'
                        &&
                        <RecentProfiles api={api} />
                    }
                    {
                        pageType === 'flag'
                        &&
                        <MakeAFlag api={api} />
                    }
                    {
                        pageType === 'contact'
                        &&
                        <ContactUs />
                    }
                    </section>
                </div>
            </main>
            <div className="w-full h-[1%] flex items-center justify-end text-zinc-700 pr-16">
                <div className=" transition-all duration-150 hover:cursor-pointer hover:font-bold"
                onClick={() => {
                    window.open('https://www.jensenlabs.dev', '_blank')?.focus();
                }}>
                    Powered by Jensen Labs
                </div>
            </div>
        </main>
    )
}


function signOut(instance: IPublicClientApplication)
{
    instance.logout().catch(e => {
        console.log(e);
    });
}