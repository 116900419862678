import { useAccount, useMsal } from "@azure/msal-react";
import { useState } from "react";
import { ApiServiceWorker } from "../managers/ApiServiceWorker";
import ViewReportModal from "../modals/ViewReportModal";

export default function ProfileResultsComponent({ profileList }: any)
{
    const [index, setIndex] = useState(0);
    const [profile, setProfile] = useState(profileList[index]);
    const [showReportModal, setShowReportModal] = useState(false);

    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const api = new ApiServiceWorker(`${account?.idTokenClaims?.emails![0]}`);

    return (
        <div className="w-full h-96 bg-zinc-300 rounded-xl p-4 flex flex-col items-center justify-center gap-2">
            <ViewReportModal show={showReportModal} onHide={() => {setShowReportModal(false);}} profile={profile} api={api}/>
            <span className="w-full text-start mb-2 font-semibold pl-2">Is this the Right Person?</span>
            <div className="w-full h-full flex flex-col gap-2 items-center justify-start">
                <div className="w-full h-[40%] bg-zinc-100 rounded-xl px-4 text-xl font-semibold flex items-center">{profile?.name}</div>
                <div className="w-full h-[20%] bg-zinc-100 rounded-xl px-4 text-lg font-normal flex items-center">{profile?.email === '' ? 'no email provided' : profile.email}</div>
                <div className="w-full h-[20%] bg-zinc-100 rounded-xl px-4 text-lg font-normal flex items-center">{profile?.phoneNumber ?? 'no phone number'}</div>
                <div className="w-full h-[20%] bg-zinc-100 rounded-xl px-4 text-lg font-normal flex items-center">{profile?.address === '' ? 'no address' : profile.address}</div>
                <p className="text-end font-semibold">This profile has appeared {profile?.searchCount} times in searches.</p>
            </div>
            <div className="w-full h-[20%] flex flex-row justify-around">
                <button className="p-2 px-4 rounded-xl bg-zinc-900 text-white transition-all duration-150 hover:bg-zinc-700 disabled:bg-zinc-500" onClick={() => {
                    setProfile(profileList[index - 1]);
                    setIndex(index - 1);
                }} disabled={index === 0}>
                    Prev
                </button>
                <button className="p-2 px-4 rounded-xl bg-red-600 font-semibold text-white" onClick={() => {
                    // TRIGGER THE RECENTS TO UPDATE!!!!
                    api.updateRecents(profile);
                    setShowReportModal(true);
                }}>
                    View Flags
                </button>
                <button className="p-2 px-4 rounded-xl bg-zinc-900 text-white transition-all duration-150 hover:bg-zinc-700 disabled:bg-zinc-500" onClick={() => {
                    setProfile(profileList[index + 1]);
                    setIndex(index + 1);
                }} disabled={index === profileList.length - 1}>
                    Next
                </button>
            </div>
        </div>
    )
}