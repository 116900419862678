import { v4 as uuidv4 } from 'uuid';
import { Profile } from './Profile';

export class Report
{
    public id: string
    public businessThatReported: string
    public name: string
    public emailAddress: string
    public phoneNumber: string
    public reportDate: string;
    public address: string;
    public comments: string;

    constructor(profile: Profile, businessThatReported: string, comments: string)
    {
        this.id = uuidv4();
        this.businessThatReported = businessThatReported;
        this.name = profile.name;
        this.emailAddress = profile.email;
        this.phoneNumber = profile.phoneNumber;
        this.address = profile.address;
        this.comments = comments;
        this.reportDate = new Date().toLocaleString();
    }
}