import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";
import { v4 } from "uuid";
import { Profile } from "../models/Profile";
import { Report } from "../models/Report";

export class ApiServiceWorker
{
    private blobServiceClient: BlobServiceClient;
    private personalProfileContainer!: ContainerClient; // nullable because
    private publicContainer: ContainerClient;

    constructor(accountName: string)
    {
        this.blobServiceClient = new BlobServiceClient(`${process.env.REACT_APP_AZURE_SAS_URL}`);
        const moddedProfileName = accountName.replace('@', '-').replace('.', '-');
        if (process.env.NODE_ENV === 'production')
        {
            // REDO THIS. FUCKING SHIT KEEPS BREAKING.
            this.blobServiceClient.getContainerClient(`${moddedProfileName}`).exists()
            .then(res => {
                if (!res)
                {
                    this.blobServiceClient.createContainer(`${moddedProfileName}`)
                    .then(container => {
                        this.personalProfileContainer = container.containerClient;
                    });
                }
                else
                {
                    this.personalProfileContainer = this.blobServiceClient.getContainerClient(`${moddedProfileName}`);
                }
            })

            this.publicContainer = this.blobServiceClient.getContainerClient('public');
        }
        else
        {
            // REDO THIS. FUCKING SHIT KEEPS BREAKING.
            this.blobServiceClient.getContainerClient(`${moddedProfileName}-dev`).exists()
            .then(res => {
                if (!res)
                {
                    this.blobServiceClient.createContainer(`${moddedProfileName}-dev`)
                    .then(container => {
                        this.personalProfileContainer = container.containerClient;
                    });
                }
                else
                {
                    this.personalProfileContainer = this.blobServiceClient.getContainerClient(`${moddedProfileName}-dev`);
                    
                }
            })

            this.publicContainer = this.blobServiceClient.getContainerClient('public-dev');
        }
        
    }


    async makeSearch(name: string, email: string, phoneNumber: string, address: string): Promise<Profile[] | null>
    {
        // Bogus search. ignore.
        if (name === '')
        {
            return null;
        }
        else if (name === '' && email === '' && phoneNumber === undefined && address === '')
        {
            return null;
        }

        // TODO: Make a search, return the list of Profiles that meet, or return the singular profile from the search (and create it in public.)
        // Step 1. Pull the list from the public container for 'profiles'. If this profile exists in the list, then pull it. If it doesn't, create it, store it and return this.
        if (!await this.publicContainer.getBlockBlobClient('profiles').exists())
        {
            // Prelim check to create the container if it doesn't exist already.
            await this.publicContainer.getBlockBlobClient('profiles').upload(JSON.stringify([]), JSON.stringify([]).length);
        }

        // Step 2. Pull the list from the container and start checking
        let rawProfileList = await (await (await this.publicContainer.getBlockBlobClient('profiles').download()).blobBody)?.text();
        let profileList = JSON.parse(`${rawProfileList}`) as Profile[];

        let matchingProfiles: Profile[] = [];
        for (var profile of profileList)
        {
            switch (true)
            {
                case name !== '' && profile.name.toLocaleLowerCase().includes(name.toLocaleLowerCase()):
                case email !== '' && profile.email.toLocaleLowerCase().includes(email.toLocaleLowerCase()):
                case phoneNumber !== undefined && profile?.phoneNumber?.includes(phoneNumber):
                case address !== '' && profile.address.toLocaleLowerCase().includes(address.toLocaleLowerCase()):
                    matchingProfiles.push(profile);
            }
        }

        // Step 3. Update all the of the found profiles in the list and then store them.
        // Update each of their search values and update the profiles board.
        if (matchingProfiles.length > 0)
        {
            for (var matchedProfile of matchingProfiles)
            {
                // eslint-disable-next-line no-loop-func
                profileList[profileList.findIndex(x => x.id === matchedProfile.id)].searchCount += 1;
            }
            await this.publicContainer.getBlockBlobClient('profiles').upload(JSON.stringify(profileList), JSON.stringify(profileList).length); // We don't care about the result of this.
        }
        
        // Step 5. Return the search.
        return matchingProfiles;
    }

    async createNewProfile(name: string, email: string, phoneNumber: string, address: string): Promise<Profile | null>
    {
        // This creates a new profile from the modal window.
        let rawProfileList = await (await (await this.publicContainer.getBlockBlobClient('profiles').download()).blobBody)?.text();
        let profileList = JSON.parse(`${rawProfileList}`) as Profile[];
            
        // Means that the profile wasn't found, so let's create a new profile, store it, then return this.
        let newProfile = new Profile();
        newProfile.id = v4();
        newProfile.name = name;
        newProfile.email = email;
        newProfile.phoneNumber = phoneNumber;
        newProfile.address = address;

        // Update the repository 
        profileList.push(newProfile);
        let uploadResult = await this.publicContainer.getBlockBlobClient('profiles').upload(JSON.stringify(profileList), JSON.stringify(profileList).length);
        if (uploadResult._response.status === 201)
        {
            return newProfile;
        }
        else
        {
            return null;
        }
    }


    async getRecents(): Promise<Profile[]>
    {
        if (!await this.publicContainer.getBlockBlobClient('profiles').exists())
        {
            await this.publicContainer.getBlockBlobClient('profiles').upload(JSON.stringify([]), JSON.stringify([]).length);
        }

        if (!await this.personalProfileContainer.getBlockBlobClient('recents').exists())
        {
            await this.personalProfileContainer.getBlockBlobClient('recents').upload(JSON.stringify([]), JSON.stringify([]).length);
        }

        let rawRecentsList = await (await (await this.personalProfileContainer.getBlockBlobClient('recents').download()).blobBody)?.text();
        let recentsList = JSON.parse(`${rawRecentsList}`) as string[];
        

        // TODO: Fetch the profiles from the list.
        let rawProfileList = await (await (await this.publicContainer.getBlockBlobClient('profiles').download()).blobBody)?.text();
        let profileList = JSON.parse(`${rawProfileList}`) as Profile[];

        let filteredIds = recentsList.map((id: string) => profileList.find(x => x.id === id)) as Profile[];

        return filteredIds;
    }


    async updateRecents(profile: Profile): Promise<void>
    {
        // This function is only called when someone goes to view flaggings. Essentially we trigger the payment when they click on it.

        // Step 1. Pull the recents list, check if any of the matching profiles are in there. Replace the ones that aren't.
        if (!await this.personalProfileContainer.getBlockBlobClient('recents').exists())
        {
            await this.personalProfileContainer.getBlockBlobClient('recents').upload(JSON.stringify([]), JSON.stringify([]).length);
        }

        let rawRecentsList = await (await (await this.personalProfileContainer.getBlockBlobClient('recents').download()).blobBody)?.text();
        let recentsList = JSON.parse(`${rawRecentsList}`) as string[];


        // We only care about new ones. If this person is already in the system, we don't care.
        if (recentsList.find(x => x === profile.id) == null)
        {
            // New recent to add.
            recentsList.push(profile.id);
            await this.personalProfileContainer.getBlockBlobClient('recents').upload(JSON.stringify(recentsList), JSON.stringify(recentsList).length);
        }
    }


    async getAllFlaggings(profile: Profile): Promise<Report[]>
    {
        let list: Report[] = []
        for await (var fileName of profile.flagNames)
        {
            let report = await (await (await this.publicContainer.getBlockBlobClient(fileName).download()).blobBody)?.text();
            list.push(JSON.parse(`${report}`) as Report);
        }
        return list;
    }


    async addFlagToProfile(profile: Profile, report: Report): Promise<boolean>
    {
        let newFileName = `FLAG-${profile.id}-${v4()}`;
        let uploadResponse = await this.publicContainer.getBlockBlobClient(newFileName).upload(JSON.stringify(report), JSON.stringify(report).length);
        if (uploadResponse._response.status === 201)
        {
            // If that's true, we update the report count against the user!!!!!
            let rawProfileList = await (await (await this.publicContainer.getBlockBlobClient('profiles').download()).blobBody)?.text();
            let profileList = JSON.parse(`${rawProfileList}`) as Profile[];
            profileList[profileList.findIndex(x => x.id === profile.id)].reportCount += 1;
            if (!profileList[profileList.findIndex(x => x.id === profile.id)]?.flagNames)
            {
                profileList[profileList.findIndex(x => x.id === profile.id)].flagNames = [];
            }
            profileList[profileList.findIndex(x => x.id === profile.id)].flagNames.push(newFileName);
            await this.publicContainer.getBlockBlobClient('profiles').upload(JSON.stringify(profileList), JSON.stringify(profileList).length); // We don't care about the result of this.
            return true;
        }
        else
        {
            alert('There was an error filing your report! Please contact support if this error is repeating!');
            return false;
        }
    }
}