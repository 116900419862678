export class Profile
{
    public id: string = '';
    public name: string = '';
    public email: string = '';
    public phoneNumber: string = '';
    public address: string = '';
    public searchCount: number = 0;
    public reportCount: number = 0;

    public flagNames: string[] = [];
}