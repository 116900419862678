import React from 'react';

// TODO: PUT IN THE ABBREVIATED USERNAME OF THE PERSON TO PUT HERE.
// FOR INSTANCE: ANGUS MOORE => AM (IN A RANDOM COLOUR)
export const ProfilePicture = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));