import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ScaleLoader } from "react-spinners";
import { ApiServiceWorker } from "../managers/ApiServiceWorker";
import { Profile } from "../models/Profile";
import { Report } from "../models/Report";

export default function ViewReportModal(props: any)
{
    const api = props.api as ApiServiceWorker;
    const profile = props.profile as Profile ?? new Profile();
    const [isSearching, setIsSearching] = useState(false);
    const [allFlaggings, setAllFlaggings] = useState<Report[]>([]);

    useEffect(() => {
        // TODO: Download all the reports of the user, then load them into the browser here!!!
        if (props.show)
        {
            setIsSearching(true);
            api.getAllFlaggings(profile)
            .then(flags => {
                setAllFlaggings(flags);
                setIsSearching(false);
            })
            .catch((e) => console.error(e));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    return (
        <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header className="bg-gradient-to-r from-orange-500 to-purple-600 text-white">
                <Modal.Title>
                    <p className="text-3xl">Profile Summary for {profile.name}</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-xl text-black p-4">
                <p><b>NAME:</b> {profile.name}</p>
                <p><b>EMAIL: </b>{profile.email === '' ? 'no email provided' : profile.email}</p>
                <p><b>PHONE NUMBER: </b>{profile.phoneNumber ?? 'no phone number provided'}</p>
                <p><b>ADDRESS: </b>{profile.address === '' ? 'no address provided' : profile.address}</p>
                <div className="w-full overflow-auto flex-col flex items-center mt-8">
                {
                    isSearching &&
                    <div className="h-full w-full flex flex-col items-center justify-center">
                        <ScaleLoader width='5px' color='rgb(24 24 27)' />
                        <span className="text-xl font-normal">Powered by <b>Jensen Labs</b></span>
                    </div>
                }
                {
                    !isSearching && allFlaggings.length > 0 &&
                    <div className="w-full overflow-auto text-black flex flex-col">
                        {
                            allFlaggings.map((flag: Report, index: number) => {
                                return (
                                    <>
                                    <span className="text-3xl">FLAG {index + 1}</span>
                                    <span className="mb-1">Report by {flag.businessThatReported} at {flag.reportDate}</span>
                                    <div className="w-full bg-gradient-to-r from-orange-500 to-purple-600 p-1 mb-4">
                                        <div className="w-full flex flex-col items-center justify-center bg-zinc-100">
                                            <div className="p-4" dangerouslySetInnerHTML={{__html: flag.comments}} />
                                        </div>
                                    </div>
                                    </>
                                )
                            })
                        }
                    </div>
                }
                </div>
            </Modal.Body>
        </Modal>
    )
}